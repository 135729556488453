/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import {
    ApolloClient, HttpLink, InMemoryCache, ApolloLink, from,
} from '@apollo/client';
import { RetryLink } from 'apollo-link-retry';
import fetch from 'isomorphic-unfetch';
import { graphqlEndpoint, HOST, storeCode } from '@root/swift.config.js';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { removeCartId } from '@helper_cartid';
import { removeIsLoginFlagging } from '@helper_auth';
import cookies from 'js-cookie';
import getConfig from 'next/config';

// Only holds serverRuntimeConfig and publicRuntimeConfig
const { publicRuntimeConfig } = getConfig();

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: [],
        },
    },
});

const uri = typeof publicRuntimeConfig !== 'undefined' && graphqlEndpoint[publicRuntimeConfig.appEnv]
    ? graphqlEndpoint[publicRuntimeConfig.appEnv]
    : graphqlEndpoint.dev;

const host = typeof publicRuntimeConfig !== 'undefined' && HOST[publicRuntimeConfig.appEnv] ? HOST[publicRuntimeConfig.appEnv] : HOST.dev;

const uriInternal = `${host}/graphql`;
// handle if token expired
const logoutLink = onError((err) => {
    const { graphQLErrors, networkError } = err;
    if (networkError && typeof window !== 'undefined' && graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].status > 500) {
        window.location.href = '/maintenance';
    } else if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].status === 401 && typeof window !== 'undefined') {
        removeCartId();
        removeIsLoginFlagging();
        cookies.remove('customerB2B');
        // reference https://stackoverflow.com/questions/10339567/javascript-clear-cache-on-redirect
        // window.location.href = `/customer/account/login?n=${new Date().getTime()}`;
    }
});

const link = new RetryLink().split(
    (operation) => operation.getContext().request === 'internal',
    new HttpLink({
        uri: uriInternal, // Server URL (must be absolute)
        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        fetch,
    }),
    new HttpLink({
        uri, // Server URL (must be absolute)
        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        fetch,
        useGETForQueries: true,
    }),
);

/**
 * Meddleware to customize headers
 */
const middlewareHeader = new ApolloLink((operation, forward) => {
    const additionalHeader = storeCode ? { store: storeCode } : {};
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            ...additionalHeader,
        },
    }));
    return forward(operation).map((response) => {
        if (publicRuntimeConfig && publicRuntimeConfig.appEnv === 'local') {
            // eslint-disable-next-line no-console
            const consoleFn = response.errors ? console.error : console.log;
            consoleFn('APOLLO_LOGS ', {
                operationName: operation.operationName,
                query: operation.query.loc.source,
                variables: operation.variables,
                response,
                queryBody: operation.query.loc.source.body.substring(0, 100),
            });
        }
        if (typeof window !== 'undefined') {
            try {
                if (typeof window?.wzApi?.sendEvent === 'function') {
                    window.wzApi.sendEvent({
                        type: 'goal',
                        payload: {
                            url: '/graphql',
                            request: { operationName: operation?.operationName, ...(operation?.variables || {}) },
                            response,
                            method: 'POST',
                        },
                    });

                    // Process any offline calls when wzApi becomes available
                    if (Array.isArray(window.wzOfflineCalls)) {
                        window.wzOfflineCalls.forEach((call) => window.wzApi.sendEvent(call));
                        window.wzOfflineCalls = [];
                    }
                } else {
                    // console.log('function not yet defined, adding to offline calls array');
                    if (!Array.isArray(window.wzOfflineCalls)) { window.wzOfflineCalls = []; }
                    if (window.wzOfflineCalls.length < 100) {
                        window.wzOfflineCalls.push({
                            type: 'goal',
                            payload: {
                                url: '/graphql',
                                request: { operationName: operation?.operationName, ...(operation?.variables || {}) },
                                response,
                                method: 'POST',
                            },
                        });
                    }
                }
                return response;
            } catch (error) {
                console.error('Error in wzResponseMiddleware', error);
            }
        }

        return response;
    });
});

export default function createApolloClient(initialState, ctx) {
    // The `ctx` (NextPageContext) will only be present on the server.
    // use it to extract auth headers (ctx.req) or similar.
    return new ApolloClient({
        ssrMode: Boolean(ctx),
        link: from([middlewareHeader, logoutLink, link]),
        cache: new InMemoryCache({ fragmentMatcher }).restore(initialState),
        // reference https://www.apollographql.com/docs/react/development-testing/developer-tooling/#apollo-client-devtools
        // eslint-disable-next-line no-underscore-dangle
        connectToDevTools:
            typeof window !== 'undefined'
            && window.__APOLLO_CLIENT__
            && typeof publicRuntimeConfig !== 'undefined'
            && publicRuntimeConfig.appEnv === 'local',
        resolvers: {},
    });
}
